<template>
<div>
<div class="card p-2 bg-card">
   <div class="align-center-flex">
        <div>
          <input
            v-model="searchResult"
            placeholder="Search"
            class="mr-bottom form-control input-sm flex"
          />
        </div>
        <div>
          <button
            v-b-toggle.sidebar-right
            @click="openSlideBar()"
            class="btn pointer btn-outline-primary btn-sm margin-right"
            :disabled="!this.$store.state.auth.permissions.includes('threat-create')"
          >
            + Add
          </button>
        </div>
      </div>
</div>
  <b-card>
    <div class="user-select">
     

      <div class="card-company-table card card-congratulation-medal">
        <div class="mb-0 table-responsive border rounded">
          <table role="table" class="table b-table table-hover">
            <thead role="rowgroup header">
              <tr role="row">
                <th role="columnheader" scope="col">#</th>
                <th role="columnheader" scope="col">Name</th>
                <!-- <th role="columnheader" scope="col">Description</th> -->
                <th role="columnheader" scope="col">Value</th>
                <th role="columnheader" scope="col" class="text-align-right">
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="rowgroup">
              <tr
                v-for="(threat, index) in allThreat"
                :key="threat._id"
                role="row"
                class="custom-table-row"
              >
              <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
                <td role="cell">{{ threat.name }}</td>
                <!-- <td role="cell">{{ threat.description }}</td> -->
                <td role="cell">
                  <span
                    :class="[
                      {
                        'badge-danger': threat.threat_value == 5,
                      },
                      { 'badge-danger': threat.threat_value == 4 },
                      {
                        'badge-warning': threat.threat_value == 1,
                      },
                      { 'badge-warning': threat.threat_value == 2 },
                      { 'badge-primary': threat.threat_value == 3 },
                      'badge-box',
                    ]"
                    v-if="threat.threat_value"
                  >
                    {{
                      threat.threat_value == 1
                        ? "Very Low"
                        : "" || threat.threat_value == 2
                        ? " Low"
                        : "" || threat.threat_value == 3
                        ? "Medium"
                        : "" || threat.threat_value == 4
                        ? "High"
                        : "" || threat.threat_value == 5
                        ? "Very High"
                        : ""
                    }}
                  </span>
                </td>
                <td
                  role="cell"
                  class=" text-align-right"
                >
                  <span
                    @click="detailThreat(threat._id)"
                    class="icon-box-new-new pointer"
                  >
                    <feather-icon class="rotate" icon="EyeIcon" size="20" />
                  </span>
                  <span
                    @click="showModal(threat._id)"
                    class="icon-box-new-new pointer"
                  >
                    <feather-icon
                      class="delete-color rotate"
                      icon="Trash2Icon"
                      size="20"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="card-body">
            <b-modal
              id="modal-danger"
              ref="modal-danger"
              ok-only
              ok-variant="danger"
              @ok="deleteAssets()"
              modal-class="modal-danger"
              centered
              title="Delete !"
              :ok-disabled="!this.$store.state.auth.permissions.includes('threat-delete')"
            >
              <b-card-text> Are You Sure to Delete the Threat ? </b-card-text>
            </b-modal>
          </div>
        </div>
      </div>
      <div class="float-right">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        >
        </b-pagination>
      </div>
    </div>
  </b-card>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  BSidebar,
  VBToggle,
  BPagination,
  BCard,
  BModal,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
    allThreat: [],
    threatDescription: "",
    assetName: "",
    addThreatData: false,
    editThreatData: false,
    editThreatID: "",
    hideSideBar: false,
    duplicateName: false,
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: "",
    threatDeleteID: "",
    option: [
      { title: "Very Low", value: 1 },
      { title: "Low", value: 2 },
      { title: "Medium", value: 3 },
      { title: "High", value: 4 },
      { title: "Very High", value: 5 },
    ],
    threatValue: [],
  }),
  components: {
    BFormTextarea,
    BSidebar,
    BPagination,
    BCard,
    BModal,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  props: {},
  watch: {
    currentPage(newPage) {
      this.getThreat(newPage);
    },
    searchResult() {
      this.searchResultData(this.currentPage);
    },
  },
  mounted() {
    this.getThreat(this.currentPage);
  },
  computed: {},
  methods: {
    detailThreat(id) {
      this.$router.push({ name: "theratdetail", params: { id: id } });
    },
    searchResultData(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_ONECSF_API_URL +
            `/threats?name=${this.searchResult}`,
        };
        this.$http(options)
          .then((res) => {
            this.allThreat = res.data.data.data;
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    cancel() {
      this.hideSideBar = false;
      this.assetName = "";
      this.threatDescription = "";
    },
    openSlideBar() {
      this.$router.push({ name: "addthreats" });
    },
    getThreat(num) {
      if ((num !== null) | (num !== 0)) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/threats`,
          params: { page: num },
        };
        this.$http(options)
          .then((res) => {
            this.allThreat = res.data.data.data;
           
            this.currentPage = res.data.data.current_page;
            this.lastPage = res.data.data.last_page;
            this.rows = this.lastPage * 10;
            console.log(this.currentPage, this.lastPage, this.rows);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteAssets(threatID) {
      threatID = this.threatDeleteID;
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/threats/${threatID}`,
      };
      this.$http(options)
        .then((res) => {
          this.getThreat();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "TrashIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editAssets(threat) {
      this.$router.push({ name: "editthreats", params: { id: threat._id } });
    },
    updateThreat() {
      let data = {
        name: this.assetName,
        description: this.threatDescription,
      };
      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_ONECSF_API_URL + `/threats/${this.editThreatID}`,
      };
      this.$http(options)
        .then((res) => {
          this.getThreat();
          this.assetName = "";
          this.threatDescription = "";
          (this.editThreatData = false), (this.hideSideBar = false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addThreat() {
      const body = {
        name: this.assetName,
        description: this.threatDescription,
      };
      const duplicateData = this.allThreat.filter(
        (ele) => ele.name.toLowerCase() == this.assetName.toLowerCase()
      );
      if (!duplicateData.length) {
        const options = {
          method: "POST",
          data: body,
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_ONECSF_API_URL + `/threats`,
        };
        this.$http(options)
          .then((res) => {
            this.getThreat();
            this.hideSideBar = !this.hideSideBar;
            this.duplicateName = false;
            this.assetName = "";
            this.threatDescription = "";
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.duplicateName = true;
      }
    },
    showModal(itemid) {
      this.threatDeleteID = itemid;
      this.$refs["modal-danger"].show();
    },
  },
};
</script>

<style>
.user-select {
  user-select: none;
}
.header {
  font-size: 20px;
  font-weight: 600;
}
.align-center-flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.transpent {
  background-color: rgba(130, 134, 139, 0.12) !important;
}
.align-center-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-20 {
  padding: 20px;
}
/* .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #f3f2f7 !important;
  color: #6e6b7b;
} */
.flex-display {
  display: inline-flex;
}
.align-right {
  display: flex;
  justify-content: flex-end;
}
.delete-color {
  color: #ea5455 !important;
}
.icon-box-new-new {
  width: 25px;
  height: 25px;
  /* background-color: #7367f0; */
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.mr-bottom {
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}
.validation {
  color: red;
  font-size: 12px;
  position: relative;
  top: -10px;
}
.table th,
.table td {
  border-top: 1px solid #e3e0e9;
}
.rowgroup tr:hover {
  background-color: #f6f6f9;
  cursor: pointer !important;
}
.text-align-right {
  text-align: right;
}
.modal .modal-header .close {
  position: relative;
  top: 4px;
  left: -10px;
}
.badge-box {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}
th{
  background: #472183 !important;
  color: #fff;
}
</style>
